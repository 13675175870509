import React from 'react'
import { css } from '@emotion/react'

import { Button } from './button'

export function Newsletter() {
  return (
    <section css={formCss}>
      <p>Chci posílat čerstvé novinky na můj e-mail.</p>
      <form
        method="post"
        action="https://gmail.us5.list-manage.com/subscribe/post"
        target="_blank">
        <input type="email" name="EMAIL" placeholder="Tvůj e-mail" required />
        <input type="hidden" name="u" value="643154de4883cdbf6ea515deb" />
        <input type="hidden" name="id" value="25f72dfa12" />
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true">
          <input
            type="text"
            name="b_643154de4883cdbf6ea515deb_25f72dfa12"
            tabIndex="-1"
          />
        </div>
        <Button type="submit">Odebírat</Button>
      </form>
    </section>
  )
}

const formCss = css`
  margin-bottom: 4rem;
  border: 1px solid #e6e6e6;
  padding: 1rem 1.5rem;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  p {
    font-size: 110%;
    text-align: center;
  }
  form {
    display: flex;
    margin: 0;
    width: 100%;
  }
  input,
  button {
    padding: 0.45rem 0.75rem;
  }
  input {
    font-weight: 300;
    width: 100%;
    margin-right: 0.5rem;
    border: 1px solid #e6e6e6;
    border-radius: 3px;
  }
  @media screen and (min-width: 768px) {
    padding: 2rem 5rem;
  }
`
