import React from 'react'
import { Helmet } from 'react-helmet'

import { Emoji } from '../components/emoji'
import { ReadLink } from '../components/link'
import { absoluteUrl } from '../utils/url'
import { Layout } from '../components/layout'

const title = 'Uuuups, stránka tu není'
const description = 'Uuuups! Zdá se, že tu není to, co hledáš... Tak to zkus příště!' // prettier-ignore

export default function NotFoundPage() {
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={absoluteUrl('/404/')} />
      </Helmet>
      <article className="no-offset">
        <h1>Uuuups!</h1>
        <p>
          Zdá se, že tu není to, co hledáš… <Emoji symbol="😟" label="Ups" />
        </p>
        <video
          src="https://media.giphy.com/media/ZsjU4Mk3SiwFy/giphy.mp4"
          type="video/mp4"
          autoPlay
          loop
          muted
        />
        <br />
        <p>
          ...ale na blogu je určitě co číst. <Emoji symbol="🙃" label="smile" />
        </p>
        <ReadLink to="/">
          <Emoji symbol="←" label="back" /> Zpět na blog
        </ReadLink>
      </article>
    </Layout>
  )
}
