import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'

import { DISQUS_SHORTNAME } from '../utils/disqus'
import { Newsletter } from '../components/newsletter'
import { Post } from '../components/post'
import { absoluteUrl } from '../utils/url'
import { Layout } from '../components/layout'
import Page404 from '../pages/404'

export default function PostTemplate({
  content,
  data: { post, image },
  location,
}) {
  if (!post) {
    return <Page404 />
  }
  const title = post.frontmatter.title
  const description = post.frontmatter.description || post.frontmatter.perex
  const coverUrl = image ? absoluteUrl(image.childImageSharp.fluid.src) : ''
  const url = absoluteUrl(location.pathname)
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={coverUrl} />
      </Helmet>
      <Post
        {...post.frontmatter}
        {...post.fields}
        content={
          content || <section dangerouslySetInnerHTML={{ __html: post.html }} />
        }
        image={image ? image.childImageSharp.fluid : null}
        timeToRead={post.timeToRead}
        full
      />
      <Newsletter />
      <DiscussionEmbed
        shortname={DISQUS_SHORTNAME}
        config={{ url, identifier: post.fields.slug, title }}
      />
    </Layout>
  )
}

/* Query */
export const query = graphql`
  query ($slug: String!, $image: String) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        category
        date
        description
        perex
        title
      }
      fields {
        slug
        categorySlug
      }
      html
      timeToRead
    }
    image: file(relativePath: { eq: $image }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
